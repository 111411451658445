<template>
  <v-dialog v-model="termsDialog" persistent width="900">
    <v-card class="border-radius">
      <v-card-title class="title">{{
        $t("terms-and-conditions")
      }}</v-card-title>
      <overlay-scrollbars :options="scrollbarOptions">
        <div :style="{ maxHeight: '400px' }">
          <v-card-text>
            <div v-html="terms"></div>
          </v-card-text>
        </div>
      </overlay-scrollbars>
      <div class="py-3 text-xs-center">
        <v-btn round class="text-none" @click="termsDialog = false">{{
          $t("cancel")
        }}</v-btn>
        <v-btn
          round
          class="theme-primary-bg theme-on-primary text-none"
          :loading="isLoading"
          @click="acceptTermsAndConditions"
          >{{ $t("accept") }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";

export default {
  data: () => ({
    isLoading: false,
    termsDialog: false,
    terms: "",
    userToken: "",
    scrollbarOptions: {
      scrollbars: { autoHide: "leave" },
      overflowBehavior: {
        x: "scroll",
      },
      sizeAutoCapable: true,
    },
  }),
  methods: {
    confirm(userToken) {
      if (!userToken) return;

      this.termsDialog = true;
      this.userToken = userToken;
      this.getTermsAndConditions();
    },
    getTermsAndConditions() {
      this.isLoading = true;

      this.$api.settings.public("global.terms_and_conditions").then(
        (response) => {
          this.isLoading = false;

          if (!response || !response.data.data.value) {
            return;
          }
          this.terms = response.data.data.value;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    acceptTermsAndConditions() {
      if (!this.userToken) return;

      this.isLoading = true;

      const params = {
        terms_accepted_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      };

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.userToken;

      axios.post(`/auth/update`, params).then(
        (response) => {
          this.isLoading = false;

          if (response.status !== 200) return;

          this.$emit("success");
        },
        () => {
          this.isLoading = false;
        },
      );
    },
  },
};
</script>
