<template>
  <v-layout
    v-if="isActive"
    row
    class="align-center justify-center fill-height authorize"
  >
    <v-card width="635" class="py-5">
      <v-window v-model="window">
        <v-window-item :value="0">
          <PasswordAuthorizationForm />
          <div class="px-5 text-center sw-caption">
            {{ $t("login.dont_have_password") }}
            <span
              @click="window = 1"
              class="cursor-pointer text-decoration-underline"
              >{{ $t("login.change_window") }}</span
            >
          </div>
        </v-window-item>
        <v-window-item :value="1">
          <PinAuthorizationForm />
          <div class="px-5 text-center sw-caption">
            {{ $t("login.have_password") }}
            <span
              @click="window = 0"
              class="cursor-pointer text-decoration-underline"
              >{{ $t("login.change_window") }}</span
            >
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </v-layout>
</template>

<script>
import PasswordAuthorizationForm from "@/components/Authorize/PasswordAuthorizationForm.vue";
import PinAuthorizationForm from "@/components/Authorize/PinAuthorizationForm.vue";

export default {
  data: () => ({
    isActive: true,
    window: 1,
  }),
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  components: {
    PasswordAuthorizationForm,
    PinAuthorizationForm,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.currentUser && vm.currentUser.id) {
        next({
          name: "home",
        });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.isActive = false;
    next();
  },
};
</script>
