<template>
  <div class="password-auth">
    <div class="mb-5 px-135 sw-h5 text-center">
      {{ $t("login.with_password") }}
    </div>
    <v-form
      data-vv-scope="authorize"
      @submit.prevent="authorize"
      class="px-135"
    >
      <v-text-field
        v-model="email"
        v-validate.disable="'required|email'"
        data-vv-name="email"
        :data-vv-as="$t('login.email_placeholder')"
        :placeholder="$t('login.email_placeholder')"
        hide-details
        autofocus
      ></v-text-field>
      <ErrorMessages :error-messages="errors.collect('authorize.email')" />
      <div class="mb-4">
        <v-text-field
          v-model="password"
          @click:append="isPasswordVisible = !isPasswordVisible"
          :type="isPasswordVisible ? 'text' : 'password'"
          v-validate="'required'"
          data-vv-name="password"
          :data-vv-as="$t('login.password_placeholder')"
          :placeholder="$t('login.password_placeholder')"
          :append-icon="isPasswordVisible ? 'visibility' : 'visibility_off'"
          hide-details
        ></v-text-field>
        <ErrorMessages :error-messages="errors.collect('authorize.password')" />
      </div>
      <div class="mb-5">
        <AppLanguageSwitcher />
      </div>
      <div class="mb-5 text-center">
        <v-btn
          type="submit"
          round
          large
          class="ma-0 sw-accent-bg sw-on-accent text-none"
          :loading="isLoading"
          >{{ $t("login.log_in") }}</v-btn
        >
      </div>
    </v-form>
    <TermsAndConditions ref="termsAndConditions" @success="storeUserDetails" />
  </div>
</template>

<script>
import AppLanguageSwitcher from "@/components/AppLanguageSwitcher.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

export default {
  data: () => ({
    isLoading: false,
    isPasswordVisible: false,
    window: 0,
    email: "",
    password: "",
    user: {
      token: "",
    },
  }),
  components: {
    AppLanguageSwitcher,
    TermsAndConditions,
  },
  methods: {
    storeUserDetails() {
      this.$store.commit("SET_USER", this.user);
      this.$store.commit("SET_TOKEN", this.user.token);

      this.$router.push("groups", () => {});
    },
    async authorize() {
      const isValid = await this.$validator.validateAll("authorize");

      if (!isValid) return;

      try {
        this.isLoading = true;

        const params = {
          email: this.email,
          password: this.password,
        };

        const response = await this.$api.auth.login(params);

        this.isLoading = false;

        this.user = response.data.data;

        if (!response.data.data.is_latest_terms_accepted) {
          this.$refs.termsAndConditions.confirm(response.data.data.token);
        } else {
          this.storeUserDetails();
        }
      } catch (error) {
        this.errors.add({
          scope: "authorize",
          field: "email",
          msg: this.$t("login.user_not_found"),
        });
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-auth {
  .px-135 {
    padding: 0 135px;
  }
}
</style>
