<template>
  <div class="pin-auth">
    <v-window v-model="step">
      <v-window-item :value="0">
        <div class="mb-5 px-135 sw-h5 text-center">
          {{ $t("login.with_pin") }}
        </div>
        <v-form
          data-vv-scope="reminder"
          @submit.prevent="reminder"
          class="px-135"
        >
          <div class="mb-4">
            <v-text-field
              v-model="email"
              v-validate.disable="'required|email'"
              data-vv-name="email"
              :data-vv-as="$t('login.email_placeholder')"
              :placeholder="$t('login.email_placeholder')"
              :disabled="isLoading"
              hide-details
              autofocus
            ></v-text-field>
            <ErrorMessages :error-messages="errors.collect('reminder.email')" />
          </div>
          <div class="mb-5">
            <AppLanguageSwitcher />
          </div>
          <div class="pb-3 text-center">
            <v-btn
              type="submit"
              round
              large
              class="ma-0 sw-accent-bg sw-on-accent text-none"
              :loading="isLoading"
              >{{ $t("login.send_code") }}</v-btn
            >
          </div>
        </v-form>
      </v-window-item>
      <v-window-item :value="1">
        <div class="mb-5 px-135 sw-h5 text-center">
          {{ $t("login.insert_verification_code", { email: email }) }}
        </div>
        <v-form
          data-vv-scope="authorize"
          @submit.prevent="authorize"
          class="px-135"
        >
          <div class="mb-4">
            <v-text-field
              v-model="pin"
              v-validate.disable="'required|digits:4'"
              data-vv-name="pin"
              :data-vv-as="$t('login.pin_placeholder')"
              :placeholder="$t('login.pin_placeholder')"
              :disabled="isLoading"
              hide-details
              autofocus
            ></v-text-field>
            <ErrorMessages :error-messages="errors.collect('authorize.pin')" />
          </div>
          <div class="pb-3 text-center">
            <v-btn
              type="submit"
              round
              large
              class="ma-0 sw-accent-bg sw-on-accent text-none"
              :loading="isLoading"
              >{{ $t("login.log_in") }}</v-btn
            >
          </div>
        </v-form>
      </v-window-item>
    </v-window>
    <div class="mb-5 text-center">
      <span @click="toggle(0)" class="mx-1 caption">
        <font-awesome-icon
          :icon="step === 0 ? ['fa', 'circle'] : ['far', 'circle']"
          class="sw-accent"
        />
      </span>
      <span @click="toggle(1)" class="mx-1 caption">
        <font-awesome-icon
          :icon="step === 1 ? ['fa', 'circle'] : ['far', 'circle']"
          class="sw-accent"
        />
      </span>
    </div>
    <TermsAndConditions ref="termsAndConditions" @success="storeUserDetails" />
  </div>
</template>

<script>
import AppLanguageSwitcher from "@/components/AppLanguageSwitcher.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

export default {
  data: () => ({
    isReady: false,
    isLoading: false,
    step: 0,
    email: "",
    pin: "",
    user: {
      token: "",
    },
  }),
  components: {
    AppLanguageSwitcher,
    TermsAndConditions,
  },
  methods: {
    storeUserDetails() {
      this.$store.commit("SET_USER", this.user);
      this.$store.commit("SET_TOKEN", this.user.token);

      this.$router.push("groups", () => {});
    },
    async reminder() {
      const isValid = await this.$validator.validateAll("reminder");

      if (!isValid) return;

      try {
        this.isLoading = true;

        const params = {
          email: this.email,
        };

        await this.$api.auth.reminder(params);

        this.isLoading = false;
        this.step = 1;
      } catch (error) {
        this.$validator.errors.add({
          scope: "reminder",
          field: "email",
          msg: this.$t("login.user_not_found"),
        });
        this.isLoading = false;
      }
    },
    async authorize() {
      const isValid = await this.$validator.validateAll("authorize");

      if (!isValid) return;

      try {
        this.isLoading = true;

        const params = {
          email: this.email,
          pin: this.pin,
        };

        const response = await this.$api.auth.login(params);

        this.isLoading = false;

        this.user = response.data.data;

        if (!response.data.data.is_latest_terms_accepted) {
          this.$refs.termsAndConditions.confirm(response.data.data.token);
        } else {
          this.storeUserDetails();
        }
      } catch (error) {
        this.errors.add({
          scope: "authorize",
          field: "pin",
          msg: this.$t("login.user_not_found"),
        });
        this.isLoading = false;
      }
    },
    toggle(n) {
      if (n === 1) {
        return this.reminder();
      }

      this.step = n;
    },
  },
};
</script>

<style lang="scss" scoped>
.pin-auth {
  .px-135 {
    padding: 0 135px;
  }
}
</style>
